import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import createApiClient from './api'
import './style.css'

//let initData = 'query_id=AAFc7SUaAAAAAFztJRqdoKrP&user=%7B%22id%22%3A438693212%2C%22first_name%22%3A%22Artem%22%2C%22last_name%22%3A%22Melnik%22%2C%22username%22%3A%22artmelnik%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1718804850&hash=3d58ee6418dd03e5919787ad83ec39b7193cb91dcf6817a834c2e95a2bbebbd9';
let initData = '';
if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
    initData = window.Telegram.WebApp.initData
}

const app = createApp(App);

let apiClient = createApiClient(initData);
app.config.globalProperties.$api = apiClient;

async function loadUser() {
    try {
        const response = await apiClient.get('/user');
        console.log(response.data)
        return response.data.data;
    } catch (error) {
        console.log(error)
    }
}

(async () => {
    try {
        app.config.globalProperties.$user = await loadUser();
        app.config.globalProperties.$init_data = initData;

        app.use(store);
        app.use(router);
        app.mount('#app');
    } catch (error) {
        console.error('Initialization failed:', error);
    }
})();
