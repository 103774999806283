import axios from 'axios';

const createApiClient = (initData, lang = 'en') => {
    return axios.create({
        baseURL: 'https://bg.marketninja.trade/bot',
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': initData,
            'lang': lang
        },
    });
};

export default createApiClient;
