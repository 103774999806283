<template>
  <div class="bg-black min-h-full-screen relative">
    <router-view/>
    <div class="fixed bottom-0 left-0 w-full bg-black border-t border-white/10">
      <nav class="w-full grid grid-cols-2 text-white/65">
        <router-link
          to="/"
          :class="[
            'flex items-center justify-center py-4',
            isActiveMenuItem('home') && 'text-red-600'
          ]"
        ><SignalIcon class="w-6 h-6 mr-2" /> Signals</router-link>
        <router-link
          to="/settings"
          :class="[
            'flex items-center justify-center py-4',
            isActiveMenuItem('settings') && 'text-red-600'
          ]"
        ><AdjustmentsVerticalIcon class="w-6 h-6 mr-2" /> Settings</router-link>
      </nav>
    </div>
  </div>
</template>
<script>
import {SignalIcon, AdjustmentsVerticalIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    SignalIcon,
    AdjustmentsVerticalIcon,
  },

  methods: {
    isActiveMenuItem(key) {
      if (key === 'home' && this.$route.fullPath === '/') {
        return true
      }

      return this.$route.fullPath.includes(key)
    }
  },

  mounted() {
    console.log(this.$route)
  }
}
</script>