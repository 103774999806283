<template>
  <div class="h-full flex flex-col">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">Settings</div>

      <div class="custom-bg-gray rounded-xl mt-5 px-3">
        <router-link to="/settings/language" class="flex items-center py-2 flex-shrink-0">
          <div class="bg-purple-500 w-7 h-7 rounded-lg text-white flex items-center justify-center flex-shrink-0">
            <LanguageIcon class="w-5 h-5" />
          </div>
          <div class="text-white/75 pl-3 flex items-center justify-between w-full">
            Language
            <div class="opacity-50 flex items-center">
              English <ChevronRightIcon class="ml-2 w-5 h-5" />
            </div>
          </div>
        </router-link>
        <router-link to="/settings/signals" class="flex items-center py-2 flex-shrink-0 border-t border-white/5">
          <div class="bg-red-600 w-7 h-7 rounded-lg text-white flex items-center justify-center flex-shrink-0">
            <SignalIcon class="w-5 h-5" />
          </div>
          <div class="text-white/75 pl-3 flex items-center justify-between w-full">
            Signals
            <div class="opacity-50 flex items-center">
              <ChevronRightIcon class="w-5 h-5" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {LanguageIcon, ChevronRightIcon, SignalIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    LanguageIcon,
    ChevronRightIcon,
    SignalIcon,
  },

  mounted() {
  }
}
</script>