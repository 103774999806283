<template>
  <div class="h-full flex flex-col pb-20" :class="[loading ? 'items-center justify-center' : '']">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">Last signals</div>
      <div class="text-white/45">Latest actual signals for half an hour</div>
    </div>
    <template v-if="loading">
      <div class="flex justify-center items-center h-full">
        <LoadingBlock w="w-10" h="h-10" />
      </div>
    </template>
    <template v-else>
      <div>
        <div v-for="signal in signals.data" :key="signal.id" class="px-6 mt-4">
          <SignalItem :item="signal" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/base/LoadingBlock.vue'
import SignalItem from '@/components/signals/SignalItem.vue'

export default {
  name: 'HomeView',

  components: {
    LoadingBlock,
    SignalItem,
  },

  methods: {
    onLoadSignals() {
      this.$api.get('/signals', {
        params: {
          period: '15m'
        }
      }).then((result) => {
        console.log(result)
        this.signals = result.data

      }).finally(() => {
        this.loading = false
      })
    }
  },

  data() {
    return {
      loading: true,
      signals: null,
    }
  },

  mounted() {
    this.onLoadSignals()
  }
}
</script>
