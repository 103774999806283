<template>
  <div class="rounded-xl p-4 custom-bg-gray space-y-4 text-white">
    <!-- Заголовок: символ и период -->
    <div class="flex items-center justify-between">
      <h2 class="text-lg font-bold text-white/75">{{ item.symbol }} ({{ item.period }})</h2>
      <span
          :class="{
          'text-red-500': item.signal_type === 'sell',
          'text-green-500': item.signal_type === 'buy'
        }"
          class="font-semibold uppercase"
      >
        {{ item.signal_type }}
      </span>
    </div>

    <!-- Основные данные -->
    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-1">
        <p class="text-sm flex flex-col">
          <span class="text-white/50">Price Change:</span> {{ item.price_change }}
        </p>
        <p class="text-sm flex flex-col">
          <span class="text-white/50">% Change:</span> {{ item.percentage_price_change }}%
        </p>
        <p class="text-sm flex flex-col">
          <span class="text-white/50">RSI:</span> {{ item.rsi }}
        </p>
      </div>
      <div class="space-y-1">
        <p class="text-sm flex flex-col">
          <span class="text-white/50">MACD:</span> {{ item.macd.macd }}
        </p>
        <p class="text-sm flex flex-col">
          <span class="text-white/50">Signal:</span> {{ item.macd.signal }}
        </p>
        <p class="text-sm flex flex-col">
          <span class="text-white/50">Histogram:</span> {{ item.macd.histogram }}
        </p>
      </div>

      <!-- Объём -->
      <div class="space-y-1">
        <p class="text-sm flex flex-col">
          <span class="text-white/50">Volume:</span> {{ item.volume.current_volume }}
        </p>
      </div>
      <div class="space-y-1">
        <p class="text-sm flex flex-col">
          <span class="text-white/50">Avg:</span> {{ item.volume.average_volume }}
        </p>
      </div>
    </div>

    <!-- Время обновления -->
    <div class="text-xs flex justify-between items-center">
      <div class="text-sm flex">
        <span class="text-white/50">Signal №:</span>
        <span class="font-medium ml-2">{{ item.signal_number }}</span>
      </div>
      <div class="text-white/50">
        <span>Updated At:</span> {{ new Date(item.updated_at).toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
